<template>
  <div :class="wrapperClasses">
    <popover
      v-bind="dynamicProps()"
      :align="align"
      :placement="placement"
      :trigger="trigger"
      :mouse-enter-delay="mouseEnterDelay"
      :mouse-leave-delay="mouseLeaveDelay"
      :overlay-class-name="wrapperClasses"
      :get-popup-container="getPopupContainer"
      :auto-adjust-overflow="autoAdjustOverflow"
      :destroy-tooltip-on-hide="destroyOnClose"
      @open-change="onVisibleChange"
    >
      <!-- default slot is for the item that triggers a popover -->
      <slot>
        <span
          :class="showMenu && isVisible && 'active'"
          class="bitts-overflow-menu__default-toggle"
        >
          <FontAwesomeIcon
            :icon="['fak', 'menu']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          />
        </span>
      </slot>
      <!-- content slot is for popover content -->
      <template #content>
        <slot name="content" />
      </template>
    </popover>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Popover } from 'ant-design-vue';

export default {
  name: 'BittsPopover',
  components: {
    Popover,
    FontAwesomeIcon,
  },
  props: {
    align: {
      type: Object,
      default: undefined,
    },
    overlayClass: {
      type: String,
      default: '',
    },
    autoAdjustOverflow: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom',
      validator: (val) => {
        return (
          [
            'top',
            'right',
            'bottom',
            'left',
            'topLeft',
            'topRight',
            'bottomLeft',
            'bottomRight',
          ].indexOf(val) !== -1
        );
      },
    },
    trigger: {
      type: String,
      default: 'click',
      validator: (val) => {
        return ['hover', 'click', 'focus', 'contextmenu'].indexOf(val) !== -1;
      },
    },
    mouseEnterDelay: {
      type: Number,
      default: 0,
    },
    mouseLeaveDelay: {
      type: Number,
      default: 0.1,
    },
    mountToBody: {
      type: Boolean,
      default: false,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: null,
    },
    disableTransition: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'fade',
    },
    autoclose: {
      type: Number,
      default: 0,
    },
  },
  emits: ['menu-visible'],
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    wrapperClasses() {
      const classes = ['bitts-overflow-menu'];
      if (this.overlayClass) classes.push(this.overlayClass);
      return classes.join(' ');
    },
  },
  methods: {
    onVisibleChange(visible) {
      if (this.autoclose > 0) {
        setTimeout(() => {
          this.isVisible = false;
          this.$emit('menu-visible', { visible: false });
        }, this.autoclose);
      }
      this.isVisible = visible;
      this.$emit('menu-visible', { visible });
    },
    getPopupContainer(trigger) {
      return this.mountToBody ? document.body : trigger?.parentElement;
    },
    dynamicProps() {
      const props = {};
      if (this.showMenu !== null) props.open = this.showMenu;
      if (this.autoclose > 0) props.open = this.isVisible;
      if (this.disableTransition) props.transitionName = null;
      else props.transitionName = this.transitionName;
      return props;
    },
  },
};
</script>

<style lang="pcss">
.bitts-overflow-menu {
  &.ant-popover {
    font-family: inherit;
    .ant-popover-inner {
      @apply p-0;
    }
    a:hover {
      @apply text-neutral-text-strong;
    }
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    @apply border border-neutral-border rounded-lg;
  }
  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight,
  &.ant-popover-placement-left,
  &.ant-popover-placement-leftTop,
  &.ant-popover-placement-leftBottom,
  &.ant-popover-placement-top,
  &.ant-popover-placement-topLeft,
  &.ant-popover-placement-topRight {
    padding: 0;
  }

  .bitts-overflow-menu__default-toggle {
    @apply h-24 w-24 rounded-bts-sm cursor-pointer
    flex items-center text-neutral-text-placeholder justify-center;

    &.active,
    &:hover {
      @apply bg-neutral-background;
    }
  }
}
</style>
